const urlCongigration = () => {
      // const environment = 'demoNew'
      // const environment = 'demo'
      // const environment = 'preStage'
      const environment = 'staging'
      // const environment = 'preProd'
      // const environment = 'production' 
      // const environment = 'production-pilot' 
      switch (environment) {
            case 'demoNew':
                  return {
                        adminBaseUrl: 'https://newdemo-admin.parkengage.com/',
                        cmsBaseUrl: 'https://newdemo.parkengage.com/',
                        customerPortal: 'https://demo-customerportal.parkengage.com/',
                        apiBaseURL: 'https://demo-api-new.parkengage.com'
                  }
                  break;
            case 'demo':
                  return {
                        adminBaseUrl: 'https://demo-admin.parkengage.com/',
                        cmsBaseUrl: 'https://demo.parkengage.com/',
                        customerPortal: 'https://demo-customerportal.parkengage.com/',
                        apiBaseURL: 'https://demo-api.parkengage.com'
                  }
                  break;
            case 'preStage':
                  return {
                        adminBaseUrl: 'https://pre-stage-admin.parkengage.com/',
                        cmsBaseUrl: 'https://pre-stage.parkengage.com/',
                        customerPortal: 'https://pre-stage-customerportal.parkengage.com/',
                        apiBaseURL: 'https://pre-stage-api.parkengage.com'
                  }
                  break;
            case 'staging':
                  return {
                        adminBaseUrl: 'https://staging-admin.parkengage.com/',
                        cmsBaseUrl: 'https://staging.parkengage.com/',
                        customerPortal: 'https://staging-customerportal.parkengage.com/',
                        apiBaseURL: 'https://staging-api.parkengage.com',
                  }
                  break;
            case 'preProd':
                  return {
                        adminBaseUrl: 'https://preprod-admin.parkengage.com/',
                        cmsBaseUrl: 'https://preprod.parkengage.com/',
                        customerPortal: 'https://preprod-customerportal.parkengage.com/',
                        apiBaseURL: 'https://preprod-api.parkengage.com'
                  }
                  break;
            case 'production':
                  return {
                        adminBaseUrl: 'https://partner.parkengage.com/',
                        cmsBaseUrl: 'https://parkengage.com/',
                        customerPortal: 'https://customerportal.parkengage.com/',
                        apiBaseURL: 'https://api.parkengage.com'
                  }
                  break;
            case 'production-pilot':
                  return {
                        adminBaseUrl: 'https://pilot-admin-diamond.parkengage.com/',
                        cmsBaseUrl: 'https://pilot-diamond.parkengage.com/',
                        customerPortal: 'https://pilot-permit.parkengage.com/',
                        apiBaseURL: 'https://api.parkengage.com'
                  }
                  break;
            default:
                  break;
      }

      // adminBaseUrl : 'https://test-admin.parkengage.com/',
      // cmsBaseUrl : 'https://test.parkengage.com/',
      //adminBaseUrl : 'https://localhost:8888/',
      // adminBaseUrl : 'https://partner.parkengage.com/',
      // cmsBaseUrl : 'https://parkengage.com/',
      // customerPortal: 'https://customerportal.parkengage.com/',

}
export default urlCongigration;
